<template>
    <component :is="layout">
        <div class="container-md">
            <div class="row fadeInUp" role="main">
                <div class="col-12 col-md-8 offset-md-2">
                    <div class="form-row">
                        <div class="col-12">
                            <div
                                class="d-flex flex-column flex-md-row justify-content-between align-items-lg-center align-items-md-start border-bottom border-gray-800 pb-2 mb-4"
                            >
                                <div 
                                    v-if="!goalSelected"
                                    class="px-0 py-1 m-1"
                                >
                                    <b>What are your goals?</b><br />
                                    <p class="h3">
                                        What is the main purpose of using Automation.app. We use this to optimise onboarding
                                    </p>
                                </div>
                                <div v-else class="px-0 py-1 m-1">
                                    <b>What are your roles?</b><br />
                                    <p class="h3">
                                        We will use this to define your account structure
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Goals -->
                    <div v-if="!goalSelected">
                    <div class="form-row">
                        <div class="col-12">
                            <div class="form-check">
                                <input
                                    id="goal"
                                    v-model="signup_goal"
                                    type="radio"
                                    class="form-check-input"
                                    name="goal"
                                    :value="goals.goal_1"
                                >
                                <label
                                    class="form-check-label"
                                    for="goal"
                                >{{ goals.goal_1 }}</label>
                                </div>
                                <div class="form-check">
                                <input
                                    id="goal"
                                    v-model="signup_goal"
                                    type="radio"
                                    class="form-check-input"
                                    name="goal"
                                    :value="goals.goal_2"
                                >
                                <label
                                    class="form-check-label"
                                    for="goal"
                                >{{ goals.goal_2 }}</label>
                                </div>
                                <div class="form-check">
                                <input
                                    id="goal"
                                    v-model="signup_goal"
                                    type="radio"
                                    class="form-check-input"
                                    name="goal"
                                    :value="goals.goal_3"
                                >
                                <label
                                    class="form-check-label"
                                    for="goal"
                                >{{ goals.goal_3 }}</label>
                                </div>
                                <div class="form-check">
                                <input
                                    id="goal"
                                    v-model="signup_goal"
                                    type="radio"
                                    class="form-check-input"
                                    name="goal"
                                    :value="goals.goal_4"
                                >
                                <label
                                    class="form-check-label"
                                    for="goal"
                                >{{ goals.goal_4 }} Specicfy</label>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="signup_goal === 'Others'"
                        class="form-row"
                    >
                        <div
                            class="form-group col-12"
                            :class="{'is-invalid': errors.has('details')}"
                        >
                            <textarea
                            id="details"
                            v-model="specify_goal"
                            v-validate="{required:false}"
                            rows="4"
                            type="text"
                            class="form-control"
                            name="details"
                            autocomplete="details"
                            placeholder="Tell us everything! - We'd like to understand your needs"
                            />
                            <span
                            v-show="errors.has('details')"
                            class="invalid-feedback"
                            >{{ errors.first('details') }}</span>
                        </div>
                    </div>
                    <div v-if="signup_goal">
                        <div class="form-row">
                            <div class="form-group col-12">
                                <button
                                type="submit"
                                class="btn btn-primary btn-block"
                                @click.prevent="defineUserGoal()"
                                >
                                Next
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <!-- Roles -->
                    <div v-else>
                        <div class="form-row">
                            <div class="col-12">
                                <div class="form-check">
                                <input
                                    id="goal"
                                    v-model="signup_role"
                                    type="radio"
                                    class="form-check-input"
                                    name="goal"
                                    :value="roles.role_1"
                                >
                                <label
                                    class="form-check-label"
                                    for="goal"
                                >{{ roles.role_1 }}</label>
                                </div>
                                <div class="form-check">
                                <input
                                    id="goal"
                                    v-model="signup_role"
                                    type="radio"
                                    class="form-check-input"
                                    name="goal"
                                    :value="roles.role_2"
                                >
                                <label
                                    class="form-check-label"
                                    for="goal"
                                >{{ roles.role_2 }}</label>
                                </div>
                                <div class="form-check">
                                <input
                                    id="goal"
                                    v-model="signup_role"
                                    type="radio"
                                    class="form-check-input"
                                    name="goal"
                                    :value="roles.role_3"
                                >
                                <label
                                    class="form-check-label"
                                    for="goal"
                                >{{ roles.role_3 }}</label>
                                </div>
                                <div class="form-check">
                                <input
                                    id="goal"
                                    v-model="signup_role"
                                    type="radio"
                                    class="form-check-input"
                                    name="goal"
                                    :value="roles.role_4"
                                >
                                <label
                                    class="form-check-label"
                                    for="goal"
                                >{{ roles.role_4 }} Specicfy</label>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="signup_role === 'Others'"
                            class="form-row"
                            >
                            <div
                                class="form-group col-12"
                                :class="{'is-invalid': errors.has('details')}"
                            >
                                <textarea
                                id="details"
                                v-model="specify_role"
                                v-validate="{required:false}"
                                rows="4"
                                type="text"
                                class="form-control"
                                name="details"
                                autocomplete="details"
                                placeholder="Tell us everything! - We'd like to understand your needs"
                                />
                                <span
                                v-show="errors.has('details')"
                                class="invalid-feedback"
                                >{{ errors.first('details') }}</span>
                            </div>
                        </div>
                        <div v-if="signup_role && !submitted">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <ButtonLoading
                                        :loading="loading"
                                        :title="'Finish'"
                                        :class="'btn btn-primary btn-block'"
                                        v-on:click.native="defineUserRole()"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="submitted">
                            <div class="form-row">
                                <div class="row justify-content-md-center mt-6 mr-4 ml-4">
                                    You have successfully completed the platform questionnaire.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </component>
</template>

<script>
import Default from "@/layouts/default.vue";
import { mapGetters } from 'vuex';
import jsonAPI from "@/api";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
    name: "Questionnaire",
    components: {
        Default,
        ButtonLoading,
    },
    data() {
        return {
            layout: "Default",
            loading: false,
            goals: {
                "goal_1": "Improve the Customer Experience (Marketing Automation & CRM)",
                "goal_2": "Optimize workflows with order management solution",
                "goal_3": "Just... Checking it out",
                "goal_4": "Others"
            },
            signup_goal: "",
            specify_goal: "",
            goalSelected: false,
            showNext: false,
            submitted: false,
            roles: {
                "role_1": "Representing clients as an agency",
                "role_2": "We're a single webshop",
                "role_3": "Representing a multi vendor shop setup",
                "role_4": "Others"
            },
            signup_role: "",
            specify_role: ""
        }
    },
    computed: {
      ...mapGetters(["getOrganization"]) 
    },
    methods: {
        defineUserGoal() {
            this.goalSelected = true;
            if (this.signup_goal === 'Others') {
                this.signup_goal = this.specify_goal;
            }
            const org = this.getOrganization;
            org.signup_goal = this.signup_goal;
        },
        defineUserRole() {
            this.roleSelected = true;
            if (this.signup_role === 'Others') {
                this.signup_role = this.specify_role;
            }
            const org = this.getOrganization;
            org.signup_role = this.signup_role;
            this.updateOrganization(org);
        },
        updateOrganization(org) {
            this.loading = true;
            jsonAPI
            .put("/organization/" + org.id, org)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "organization updated",
                type: "success",
              });
              this.submitted = true;
              this.loading = false;
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        }
    }
}
</script>
<style scoped>
.form-check {
    margin-bottom: 10px;
}
.form-check input {
    border-color: #8792a1 !important;
}
</style>